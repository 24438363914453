import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { AuctionStatus } from '@/types/LiveAuctionState';
import { AuthModalPages } from '@/components/Modals/AuthModal/types/AuthModalPages.enum';
import { buildUrlFromLocation, separateURL } from '@/utils/urls';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import { EventTypes } from 'redux-segment';
import { Events as facebookEvents, track as facebookTrack } from '@/components/FacebookPixel/FacebookPixel';
import { getAmplitudePageNameFromPath, getPageNameFromPath } from '@/utils/getPageNameFromPath';
import { getAnalyticsRegistrationStatus } from '@/utils/catalog/getAnalyticsRegistrationStatus';
import { getAuthToken, getBidderId, getUserData, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getBrowserDataFromUA, getClientIpAddress, getUserAgent } from './browser';
import {
    getCatalogBuyersPremium,
    getCatalogIsAutopay,
    getCatalogIsCatalogOnly,
    getCatalogIsLivePayments,
    getCatalogSellerId,
    getCatalogStartTime,
    getCatalogStatus,
    getCatalogTitle,
} from './catalog/catalogs/catalog.selectors';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { getCategoryLevel } from '@/utils/categoryGrabber';
import { getCurrencyConversion } from '@/redux/modules/currency';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { getIsWhiteLabel } from './whiteLabel';
import { getItemFacets } from './itemFacets';
import {
    getItemModelCatalogId,
    getItemModelSellerId,
    getItemModelTitle,
    getItemSummary,
} from './item/summary/itemSummary.selectors';
import { getResellerType } from './reseller';
import { getSellerName } from './seller';
import { getUserPrebidStatus } from '@/utils/analytics/bidding/biddingAnalytics.utils';
import {
    IDENTIFY_ANALYTICS,
    IDENTIFY_ANALYTICS_ACTION,
    LOAD_CATEGORY_RESULTS_SUCCESS,
    LOAD_CATEGORY_RESULTS_SUCCESS_ACTION,
    TRACK_ABANDONED_MODAL,
    TRACK_ABSENTEE_BID_FLOW_STEP,
    TRACK_ATTRIBUTION_DATA,
    TRACK_ATTRIBUTION_DATA_ACTION,
    TRACK_CLICK,
    TRACK_CLICK_ACTION,
    TRACK_CLICK_FOR_ALGOLIA,
    TRACK_CLICK_FOR_ALGOLIA_ACTION,
    TRACK_CONVERSION_FOR_ALGOLIA,
    TRACK_CONVERSION_FOR_ALGOLIA_ACTION,
    TRACK_ONBOARDING_WALKTHROUGH,
    TRACK_PAGEVIEW,
    TRACK_PAGEVIEW_ACTION,
    TRACK_PLACED_SEARCH,
    TRACK_SEND_SELLER_MESSAGE,
    TRACK_SOCIAL_NETWORK_SHARE,
    TRACK_SOCIAL_NETWORK_SHARE_ACTION,
    TRACK_TWO_FACTOR_AUTHENTICATION,
    TRACK_TWO_FACTOR_AUTHENTICATION_TRY_ANOTHER_WAY,
    TRACK_USER_AUTHENTICATED_DATA,
    TRACK_USER_AUTHENTICATED_DATA_ACTION,
    TRACK_VIEW,
    TRACK_VIEW_ACTION,
    TrackGetHelpViewed,
    TrackOnboardingWalkthroughAction,
    TrackPlacedSearchAction,
    TrackProductClicked,
    TrackSendSellerMessageAction,
    TrackSendSupportMessage,
    TrackSendSupportMessageAction,
    TrackTwoFactorAuthenticationAction,
    TrackTwoFactorAuthenticationTryAnotherWayAction,
    TrackWonItemsPageCheckoutClicked,
} from './actions';
import { isTopRatedHouse } from './topRatedSellers';
import { LOAD_SEARCH_RESULTS_SUCCESS, LOAD_SEARCH_RESULTS_SUCCESS_ACTION } from '@/redux/modules/search/search.types';
import { Location } from 'react-router-dom';
import { ModalContext } from './modal';
import { modalTypes } from '@/enums';
import { parseQueryParams } from '@/utils/queryParams';
import { recordMarketingReportsClick, recordMarketingReportsImpression } from '../api/analytics';
import { trackBidPlacedAnalytics, TrackBidPlacedBidType, trackItemSavedAnalytics } from '@/utils/analytics';
import { trackShareButtonClickedAnalytics } from '@/utils/analytics/sharing/trackShareButtonClicked.analytics';
import { TrackTwoFactorAuthentication, TrackTwoFactorAuthenticationTryAnotherWay } from '@/types/analytics';
import { UserBidPlacedItemsStatusEnum } from '@/types/item/UserBidPlacedItem';
import algoliaApi from '@/redux/api/algolia';
import api from '@/redux/api/buyNowRequest';
import cloneDeep from 'lodash/cloneDeep';
import kebabCase from 'lodash/kebabCase';
import ms from 'ms';

const KNOWN_SEARCH_ENGINES = [
    '.msn.',
    '.search.com',
    'about.com',
    'adsensecustomsearchads.com',
    'alice.',
    'alltheweb.com',
    'altavista.com',
    'applebot',
    'ask.com',
    'avg.com',
    'babylon.com',
    'baidu.',
    'biglobe.',
    'bing.',
    'centrum.cz',
    'cnn.com/search',
    'daum.net',
    'delta-search.com',
    'discretesearch.com',
    'duckduckgo.com',
    'easydialsearch.com',
    'ekolay.net',
    'eniro.se',
    'haosou.com',
    'kvasir.no',
    'lycos.',
    'mamma.com',
    'mynet.com',
    'myprivatesearch.com',
    'mysearchdial.com',
    'najdi.',
    'naver.com',
    'onet.pl',
    'onetcenter.',
    'ozu.es',
    'pchome.com',
    'pinterest.com',
    'query.searchdconvertnow.com',
    'rakuten.',
    'rambler.ru',
    'search.aol',
    'search.avira.net',
    'search.bt.com',
    'search.centurylink.com',
    'search.colonymt.com',
    'search.earthlink.net',
    'search.excite.com',
    'search.frontier.com',
    'search.media.telstra.com',
    'search.mediacomcable.com',
    'search.monstercrawler.com',
    'search.myway.com',
    'search.pch.com',
    'search.smt.docomo',
    'search.socialdownloadr.com',
    'search.twcc.com',
    'search.us.com',
    'search.xfinity.com',
    'search.yahoo.com',
    'searchall.com',
    'searchassist.verizon.com',
    'searchguide.windstream.net',
    'searchlock.com',
    'searchprivacy.co',
    'searcht.sosodesktop.com',
    'searchthis.com',
    'securesearch.co',
    'sesam.no',
    'seznam.cz',
    'so.com',
    'sogou.com',
    'startsiden.no',
    'szukacz.pl',
    'terra.com',
    'thesmartsearch.net',
    'utorrent.inspsearch.com',
    'virgilio.it',
    'voila.fr',
    'w.goo.ne.jp',
    'w.google.',
    'w.yahoo.com',
    'wp.pl',
    'www.delta-search.com',
    'yam.com',
    'yandex.',
    'yoursearch.me',
    'zdsearch.com',
];
const KNOWN_SOCIAL_NETWORKS = [
    'pinterest.',
    'twitter.',
    '/t.co',
    'facebook.',
    'yuku.',
    'netvibes.',
    'blogspot.',
    'houzz.',
    'polyvore.',
    'youtube.',
    'vk.com',
    'paper.li',
    'reddit.',
    'buzzfeed.',
    'plus.google.',
    'plus.url.google',
    'ravelry',
    'wikia.',
    'wordpress.',
    'blog.goo.ne',
    'linkedin.',
    'groups.google.',
    '.ning.',
    'stackexchange.',
    'stumbleupon.',
    'tumblr.',
    'typepad.',
    'answers.yahoo.',
];
const IGNORED_REFERRERS = ['liveauctioneers.', 'optimizely.', 'atlassian.', 'localhost', 'rainforestqa.'];

/* ANALYTIC TYPES */

// THIS OBJECT SHOULD BE COMMON ACROSS THE APPS.  IF YOU UPDATE IT HERE, UPDATE IT EVERYWHERE
export const AnalyticsTypes = {
    Page: {
        AboutUs: 'about us',
        AccountSettings: 'account settings',
        ACHTermsAndConditions: 'ach terms and conditions',
        AddCreditCard: 'add credit card',
        AuctionCalendar: 'catalog search',
        AuctionRegistration: 'auction registration',
        AuctionResults: 'auction results',
        BiddingConsole: 'bidding console',
        CardOnFile: 'card on file',
        Catalog: 'catalog',
        CatalogTerms: 'terms and conditions',
        Category: 'category',
        CertCapture: 'cert capture',
        Checkout: 'checkout page',
        CheckoutConfirmation: 'checkout confirmation',
        Consignments: 'consignments',
        CookiePolicy: 'cookie policy',
        CuratedCollection: 'curated collection',
        Discover: 'discover',
        Features: 'features',
        FollowedSellers: 'followed auctioneers',
        GetHelp: 'get help',
        Home: 'home',
        HowAuctionsWork: 'how it works',
        IntellectualProperty: 'intellectual property',
        Item: 'item',
        ItemPhotoViewer: 'item photo viewer',
        Message: 'message',
        Messages: 'messages',
        Mobile: 'mobile',
        MyAuctions: 'my auctions',
        MyBidsPast: 'my bids - past tab',
        MyBidsUpcoming: 'my bids - upcoming tab',
        MyProfile: 'my profile',
        NearMe: 'near me',
        Notifications: 'notifications',
        NotificationsPage: 'notifications page',
        PasswordReset: 'password reset',
        PriceGuide: 'price guide',
        PriceResult: 'price result',
        Privacy: 'privacy',
        PRServices: 'pr services',
        PushNotifications: 'push notifications',
        RecentItems: 'recent items',
        RecommendedItems: 'recommended items',
        Review: 'review',
        SavedItems: 'saved items',
        SavedSearchManage: 'saved searches - manage tab',
        SavedSearchRecent: 'followed searches',
        Search: 'search dropdown',
        SearchItems: 'search',
        Seller: 'auctioneer',
        SellerDirectory: 'directory',
        SellerReviews: 'reviews',
        SellerTestimonials: 'testimonials',
        SimilarItems: 'similar items',
        SoldItem: 'sold item',
        Storefront: 'storefront',
        Terminology: 'terminology',
        TermsAndConditions: 'terms and conditions',
        TermsAndConditionsPayments: 'terms and conditions payments',
        TrendingAuctions: 'trending auctions',
        WonItems: 'won items',
    },
    Track: {
        CompleteProfile: 'Completed Profile',
        FollowSeller: 'Followed Auctioneer',
        MessagedSeller: 'Messaged Seller',
        OnboardingWalkthrough: {
            AcceptedOnboardingTooltip: 'Accepted Onboarding Tooltip',
            SkippedOnboardingTooltip: 'Skipped Onboarding Tooltip',
            ViewedOnboardingTooltip: 'Viewed Onboarding Tooltip',
        },
        PlaceAbsenteeBid: 'Placed Absentee Bid',
        PlacedWinningBid: 'Placed Winning Bid',
        PlaceLiveBid: 'Placed Live Bid',
        PlaceSearch: 'Placed Search',
        RegisterForAuction: 'Requested Auction Registration',
        RegisterUser: 'Registered Account',
        RetractAbsenteeBid: 'Retracted Absentee Bid',
        SaveEasyShippingItem: 'Saved Easy Shipping Item',
        SaveItem: 'Saved Item',

        SaveSearch: 'Followed Search',
        SendSellerMessage: 'Messaged Seller',
        SendSupportMessage: 'Messaged Support',
        SetPassword: 'Set Password',
        ShareItem: 'Social Network Share',
        StartedAccountCreation: 'Started Account Creation',
        StartedAuctionRegistration: 'Started Auction Registration',
        StartedLogIn: 'Started Log-In',
        UnfollowSeller: 'Unfollowed Auctioneer',
        UnsaveItem: 'Unsaved Item',
        UnsaveSearch: 'Unfollowed Search',
        UserAuthenticated: 'User Authenticated',
        ViewEasyShippingItem: 'View Easy Shipping Item',
    },
} as const;

export type AnalyticsTypesTrackKeys = keyof typeof AnalyticsTypes.Track;
export type AnalyticsTypesPageKeys = keyof typeof AnalyticsTypes.Page;
export type AnalyticsTypesValues =
    | (typeof AnalyticsTypes.Track)[AnalyticsTypesTrackKeys]
    | (typeof AnalyticsTypes.Page)[AnalyticsTypesPageKeys];

/**
 * Segment tracking event contexts
 */
export enum EventContext {
    'autoPopModal' = 'Auto-Pop Modal',
    'consign' = 'Consign',
    'exitIntentModal' = 'Exit Intent Modal',
    'followAuctioneer' = 'Follow Auctioneer',
    'followSearch' = 'Follow Search',
    'homepageBanner' = 'Homepage Banner',
    'join' = 'Join',
    'loginModal' = 'Log-In Modal',
    'placeBid' = 'Place Bid',
    'registerForAuction' = 'Register for Auction',
    'saveItem' = 'Save Item',
    'seeSimilarItems' = 'See Similar Items',
    'seeSoldPrice' = 'See Sold Price',
    'sendMessage' = 'Send Message',
    'signUpModal' = 'Sign-Up Modal',
    'social_register_already_exists' = 'social_register_already_exists',
}

export interface AnalyticsSlice {
    readonly algoliaAnalytics: {
        queryId: string;
        usedIndex: string;
    };
    readonly anonymousId: string;
    readonly attributionData: {
        bidderId: number;
        campaign: string;
        content: string;
        keyword: string;
        medium: string;
        source: string;
        touchDate: number;
    };
    readonly bot: boolean;
    readonly referrer: string;
    readonly touchDate: number;
}

export const defaultAnalyticsSlice: AnalyticsSlice = {
    algoliaAnalytics: {
        queryId: '',
        usedIndex: '',
    },
    anonymousId: '',
    attributionData: {
        bidderId: -1,
        campaign: 'Placeholder campaign Data',
        content: 'Placeholder content Data',
        keyword: 'Placeholder keyword Data',
        medium: 'Placeholder medium Data',
        source: 'Placeholder source Data',
        touchDate: 0,
    },
    bot: false,
    referrer: '',
    touchDate: 0,
};

export const reducer: Reducer<AnalyticsSlice> = handleActions(
    {
        [combineActions(LOAD_CATEGORY_RESULTS_SUCCESS, LOAD_SEARCH_RESULTS_SUCCESS)]: (
            state: AnalyticsSlice,
            action: LOAD_CATEGORY_RESULTS_SUCCESS_ACTION | LOAD_SEARCH_RESULTS_SUCCESS_ACTION
        ): AnalyticsSlice => {
            return {
                ...state,
                algoliaAnalytics: {
                    queryId: action.payload.queryId,
                    usedIndex: action.payload.usedIndex,
                },
            };
        },
    },
    defaultAnalyticsSlice
);

/* SELECTORS */

const stateSelector = (state: GlobalState) => state;
const analyticsSlice = createSelector(stateSelector, (state) => state.analytics);

const getReferrer = createSelector(analyticsSlice, (state) => state.referrer);

export const getIsBot = createSelector(analyticsSlice, (state) => state.bot);

const getSailthru = () => {
    // @ts-ignore
    return typeof window !== 'undefined' ? window.Sailthru : () => false;
};

const getTouchDate = createSelector(analyticsSlice, (state) => state.touchDate);

const getAlgoliaAnalytics = createSelector(analyticsSlice, (state) => state.algoliaAnalytics);

const searchList = (haystack, needle) => {
    let ret = -1;
    haystack.forEach((value) => {
        if (needle.toLowerCase().includes(value)) {
            ret = needle;
        }
    });
    return ret;
};

type GetAttributionDataQueryParams = {
    campaignSource: string;
    campaigncontent: string;
    campaignkeyword: string;
    campaignmedium: string;
    campaignname: string;
    campaignsource: string;
    gclid: string;
    gclsrc: string;
    utm_campaign: string;
    utm_content: string;
    utm_medium: string;
    utm_source: string;
    utm_term: string;
};

export type AttributionData = {
    bidderId: number;
    campaign: string;
    content: string;
    keyword: string;
    medium: string;
    source: string;
    touchDate: number;
};

export const getAttributionData = createSelector(
    [(state: GlobalState) => state, (_, location: Location) => location],
    (state, location) => {
        const { search } = location;
        const rawReferrer = getReferrer(state);
        const referrer = separateURL(rawReferrer);
        const bidderId = getBidderId(state);
        const touchDate = getTouchDate(state);

        let attributionData = {
            bidderId,
            campaign: '',
            content: '',
            keyword: '',
            medium: '',
            source: '',
            touchDate,
        };

        const engineName = searchList(KNOWN_SEARCH_ENGINES, rawReferrer);
        const networkName = searchList(KNOWN_SOCIAL_NETWORKS, rawReferrer);
        const referrerName = searchList(IGNORED_REFERRERS, rawReferrer);
        const queryParams = parseQueryParams<GetAttributionDataQueryParams>(search ?? '');

        if (queryParams.gclsrc) {
            attributionData = {
                ...attributionData,
                campaign: 'DoubleClick',
                content: 'DoubleClick',
                keyword: queryParams.gclsrc || '',
                medium: 'cpc',
                source: 'google',
            };
        } else if (queryParams.campaignsource || queryParams.campaignSource) {
            attributionData = {
                ...attributionData,
                campaign: queryParams.campaignname || '',
                content: queryParams.campaigncontent || '',
                keyword: queryParams.campaignkeyword || '',
                medium: queryParams.campaignmedium || '',
                source: queryParams.campaignsource || queryParams.campaignSource || '',
            };
        } else if (queryParams.utm_source) {
            attributionData = {
                ...attributionData,
                campaign: queryParams.utm_campaign || '',
                content: queryParams.utm_content || '',
                keyword: queryParams.utm_term || '',
                medium: queryParams.utm_medium || '',
                source: queryParams.utm_source || '',
            };
        } else if (engineName !== -1) {
            attributionData = {
                ...attributionData,
                content: referrer.path,
                medium: 'organic',
                source: referrer.host,
            };
        } else if (networkName !== -1) {
            attributionData = {
                ...attributionData,
                content: referrer.path,
                medium: 'social',
                source: referrer.host,
            };
        } else if (referrer.host !== '' && referrerName === -1) {
            attributionData = {
                ...attributionData,
                content: referrer.path,
                medium: 'referral',
                source: referrer.host,
            };
        } else {
            attributionData = {
                ...attributionData,
                campaign: 'direct',
                source: 'direct',
            };
        }
        return attributionData;
    }
);

export const getMessageSellerAnalytics = (catalogId: number, houseId: number, itemId: number) => ({
    analytics: {
        eventPayload: {
            event: AnalyticsTypes.Track.MessagedSeller,
            properties: {
                catalogId,
                houseId,
                itemId,
            },
        },
        eventType: EventTypes.track,
    },
});

export const getMessageSupportAnalytics = (catalogId: number, itemId: number) => ({
    analytics: {
        eventPayload: {
            event: AnalyticsTypes.Track.SendSupportMessage,
            properties: {
                catalogId,
                itemId,
            },
        },
        eventType: EventTypes.track,
    },
});

const mapCategoryNames = (facets) => {
    const newFacets = cloneDeep(facets);
    for (const prop in facets) {
        if (Object.prototype.hasOwnProperty.call(newFacets, prop)) {
            newFacets[prop] = newFacets[prop].map((facet) => {
                const facetLevel = getCategoryLevel(facet);
                if (facetLevel.categoryName) {
                    return facetLevel.categoryName;
                }
                return facet;
            });
        }
    }
    return newFacets;
};

const getItemPrice = (salePrice: number, leadingBid: number) => salePrice || leadingBid;

// region Item analytics
/**
 * Creates an analytics object for segment
 * @param state Current redux store
 * @param itemId The ID of the item the user interacted with
 * @param event Which Analytics event triggered the logging
 */
export const getItemAnalytics = (state: GlobalState, itemId: number, event: AnalyticsTypesValues) => {
    const {
        bidActivityCount = 0,
        bidCount = 0,
        bidderBidTimestamp = 0,
        bidderHasBid = false,
        bidderHasHighBid = false,
        bidderMaxBid = 0,
        bidderWonItem = false,
        catalogId,
        catalogStatus,
        imageVersion = 0,
        isAvailable = true,
        isLocked = false,
        isPassed = false,
        isPaused = false,
        isReserveMet = false,
        isSold = false,
        leadingBid = 0,
        salePrice = 0,
        sellerId,
        title,
    } = getItemSummary(state, itemId);

    const itemFacets = getItemFacets(state, itemId);
    const facets = mapCategoryNames(itemFacets);
    const buyersPremium = getCatalogBuyersPremium(state, catalogId);
    const topRatedHouse = isTopRatedHouse(state, sellerId);

    const {
        categories = 'n/a',
        creators = 'n/a',
        materialsTechniques = 'n/a',
        origins = 'n/a',
        stylePeriods = 'n/a',
    } = facets;

    const price = getItemPrice(salePrice, leadingBid);

    return {
        analytics: {
            eventPayload: {
                event,
                properties: {
                    biddingInfo: {
                        bidActivityCount,
                        bidCount,
                        bidderBidTimestamp,
                        bidderHasBid,
                        bidderHasHighBid,
                        bidderMaxBid,
                        bidderWonItem,
                        imageVersion,
                        isAvailable,
                        isLocked,
                        isPassed,
                        isPaused,
                        isReserveMet,
                        isSold,
                        itemId,
                        leadingBid,
                        salePrice,
                    },
                    buyersPremium,
                    catalogId,
                    catalogStatus,
                    categories,
                    creators,
                    houseId: sellerId,
                    itemId,
                    materialsTechniques,
                    origins,
                    price,
                    stylePeriods,
                    title,
                    topRatedHouse,
                },
            },
            eventType: EventTypes.track,
        },
    };
};

export const getSaveItemAnalytics = (state: GlobalState, itemId: number, location: Location) => {
    const deployment = getDeployment(state);
    const { categories } = getItemFacets(state, itemId);

    facebookTrack(facebookEvents.SavedItem);
    trackItemSavedAnalytics({
        lotCategoryLvl1: categories?.[0]?.l1CategoryName || 'Unknown',
        lotCategoryLvl2: categories?.[0]?.l2CategoryName || 'Unknown',
        pageName: getAmplitudePageNameFromPath(location.pathname),
        url: getFullUrl(deployment, location.pathname),
    });
    return getItemAnalytics(state, itemId, AnalyticsTypes.Track.SaveItem);
};

export const getUnsaveItemAnalytics = (state: GlobalState, itemId: number) =>
    getItemAnalytics(state, itemId, AnalyticsTypes.Track.UnsaveItem);

export const getSaveEasyShippingItemAnalytics = (state: GlobalState, itemId: number) =>
    getItemAnalytics(state, itemId, AnalyticsTypes.Track.SaveEasyShippingItem);

export const getViewEasyShippingItemAnalytics = (state: GlobalState, itemId: number) =>
    getItemAnalytics(state, itemId, AnalyticsTypes.Track.ViewEasyShippingItem);

export const getSellerAnalytics = (state: GlobalState, sellerId, event) => {
    const topRatedHouse = isTopRatedHouse(state, sellerId);

    const formatAnaltyics = {
        analytics: {
            eventPayload: {
                event,
                properties: {
                    houseId: sellerId,
                    topRatedHouse,
                },
            },
            eventType: EventTypes.track,
        },
    };

    return formatAnaltyics;
};
export const getFollowSearchAnalytics = () => {
    facebookTrack(facebookEvents.FollowedSearch);
    return {
        analytics: {
            eventPayload: {
                event: AnalyticsTypes.Track.SaveSearch,
            },
            eventType: EventTypes.track,
        },
    };
};

export const getUnfollowSearchAnalytics = () => ({
    analytics: {
        eventPayload: {
            event: AnalyticsTypes.Track.UnsaveSearch,
        },
        eventType: EventTypes.track,
    },
});
export const getFollowSellerAnalytics = (state: GlobalState, sellerId: number) => {
    facebookTrack(facebookEvents.FollowedAuctioneer, { houseID: sellerId });
    return getSellerAnalytics(state, sellerId, AnalyticsTypes.Track.FollowSeller);
};

export const getUnfollowSellerAnalytics = (state: GlobalState, sellerId: number) =>
    getSellerAnalytics(state, sellerId, AnalyticsTypes.Track.UnfollowSeller);

export const getCompleteAccountAnalytics = () => ({
    analytics: {
        eventPayload: {
            event: AnalyticsTypes.Track.CompleteProfile,
        },
        eventType: EventTypes.track,
    },
});

const registerAnalytics = (state: GlobalState, bidderId, attributionData: AttributionData, event) => {
    const whiteLabel = getIsWhiteLabel(state);
    return {
        eventPayload: {
            event,
            properties: {
                attribution_bidderId: bidderId,
                attribution_campaign: attributionData.campaign,
                attribution_content: attributionData.content,
                attribution_keyword: attributionData.keyword,
                attribution_medium: attributionData.medium,
                attribution_source: attributionData.source,
                attribution_touchdate: attributionData.touchDate,
                whiteLabel,
            },
        },
        eventType: EventTypes.track,
    };
};

export const getRegisterAccountAnalytics = (state: GlobalState, bidderId: number, attributionData: AttributionData) => {
    facebookTrack(facebookEvents.RegisteredAccount);
    return registerAnalytics(state, bidderId, attributionData, AnalyticsTypes.Track.RegisterUser);
};

export const getSetPasswordAnalytics = (state: GlobalState, bidderId: number, attributionData: AttributionData) =>
    registerAnalytics(state, bidderId, attributionData, AnalyticsTypes.Track.SetPassword);

export const getLoginAnalytics = (userData: any, resellerType: number) => {
    let accountAge: string;
    const nowTime = new Date().getTime();
    const createdTime = new Date(userData.createdDate).getTime();
    const ageMs = nowTime - createdTime;

    if (ageMs < ms('1w')) {
        accountAge = '1w';
    } else if (ageMs < ms('2w')) {
        accountAge = '2w';
    } else if (ageMs < ms('4w')) {
        accountAge = '4w';
    } else if (ageMs < ms('13w')) {
        accountAge = '3m';
    } else if (ageMs < ms('26w')) {
        accountAge = '6m';
    } else {
        accountAge = '6m+';
    }

    return {
        analytics: {
            eventPayload: {
                traits: {
                    accountAge,
                    annualNms: userData.ANMS,
                    createdAt: userData.createdDate,
                    creditCardValidated: userData.creditCardValidated,
                    email: userData.email,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    loggedIn: userData.bidderId > 0,
                    profileComplete: userData.profileComplete,
                    resellerType: resellerType,
                },
                userId: userData.bidderId,
            },
            eventType: EventTypes.identify,
        },
    };
};

export const getRequestedAuctionRegistrationAnalytics = (state: GlobalState, catalogId: number, itemId: number = 0) => {
    const houseId = getCatalogSellerId(state, catalogId);
    const whiteLabel = getIsWhiteLabel(state);
    const catalogStatus = getCatalogStatus(state, catalogId);
    const isCatalogOnly = getCatalogIsCatalogOnly(state, catalogId);
    const topRatedHouse = isTopRatedHouse(state, houseId);
    const autopay = getCatalogIsAutopay(state, catalogId);
    // getCatalogIsLivePayments: comes back undefined sometimes
    const livePayments = Boolean(getCatalogIsLivePayments(state, catalogId));
    const bidderId = getBidderId(state);

    const auctionAnalytics = {
        analytics: {
            eventPayload: {
                event: AnalyticsTypes.Track.RegisterForAuction,
                properties: {
                    autopay,
                    bidderId,
                    catalogId,
                    catalogStatus,
                    context: EventContext.registerForAuction,
                    houseId,
                    isCatalogOnly,
                    itemId,
                    livePayments,
                    topRatedHouse,
                    whiteLabel,
                },
            },
            eventType: EventTypes.track,
        },
    };

    facebookTrack(facebookEvents.RegisterForAuction, {
        catalogID: catalogId,
        houseID: houseId,
        itemId,
    });

    return auctionAnalytics;
};

export const getPlacedBidAnalytics = (
    state: GlobalState,
    itemId: number,
    bidAmount: number,
    event: string,
    biddingStatus: string,
    location: Location,
    step?: string
) => {
    // TODO: we need to decide what the proper definition of 'bidCount' is and then implement
    const {
        bidderHasBid,
        bidderHasHighBid,
        catalogId,
        catalogStatus,
        currency = 'USD',
        isReserveMet,
        leadingBid,
        salePrice,
        sellerId,
        title,
    } = getItemSummary(state, itemId);

    const buyersPremium = getCatalogBuyersPremium(state, catalogId);
    const catalogRegistration = getCatalogRegistration(state, catalogId);
    const bidderId = getBidderId(state);
    const price = getItemPrice(salePrice, leadingBid);
    const whiteLabel = getIsWhiteLabel(state);
    const topRatedHouse = isTopRatedHouse(state, sellerId);
    const locationPath = buildUrlFromLocation(location);
    const pageName = getPageNameFromPath(locationPath);
    const userAgent = getUserAgent(state);
    const { deviceType, mobile } = getBrowserDataFromUA(userAgent);

    // const bidCount = 1;
    const formatAnalytics = {
        eventPayload: {
            event,
            properties: {
                approvedForAuction: catalogRegistration.approved,
                // bidCount,
                bidderId,
                biddingStatus,
                buyersPremium,
                catalogId,
                catalogStatus,
                currency,
                deviceType,
                houseId: sellerId,
                itemId,
                mobile,
                orderId: `${itemId}-${bidderId}`,
                pageName,
                products: [
                    {
                        id: itemId,
                        name: title,
                        price,
                        quantity: 1,
                    },
                ],
                // Kameleoon: using the 'revenue' property that kameleoon uses to track revenue but repurposed to track bid amounts
                revenue: bidAmount,
                step: step || null,
                topRatedHouse,
                total: bidAmount,
                value: bidAmount,
                whiteLabel,
            },
        },
        eventType: EventTypes.track,
    };

    if (event === AnalyticsTypes.Track.PlaceAbsenteeBid) {
        facebookTrack(facebookEvents.PlacedAbsenteeBid, {
            catalogID: catalogId,
            currency,
            houseID: sellerId,
            itemId,
        });
    }

    const deployment = getDeployment(state);
    const { categories } = getItemFacets(state, itemId);

    trackBidPlacedAnalytics({
        auctionRegistrationStatus: getAnalyticsRegistrationStatus(catalogRegistration),
        bidAmount,
        bidAmountUsd: bidAmount * getCurrencyConversion(state, currency)?.conversionToUsd ?? 0,
        bidType:
            event === AnalyticsTypes.Track.PlaceAbsenteeBid
                ? TrackBidPlacedBidType.Absentee
                : TrackBidPlacedBidType.Live,
        catalogCurrency: currency,
        lotCategoryLvl1: categories?.[0]?.l1CategoryLabel ?? 'Unknown',
        lotCategoryLvl2: categories?.[0]?.l2CategoryLabel ?? 'Unknown',
        pageName: getAmplitudePageNameFromPath(location.pathname),
        url: getFullUrl(deployment, location.pathname),
        userPrebidStatus: getUserPrebidStatus(bidderHasBid, bidderHasHighBid, isReserveMet),
    });

    return formatAnalytics;
};

export const getPlacedAbsenteeBidAnalytics = (
    state: GlobalState,
    itemId: number,
    bidAmount: number,
    step: AbsenteeBidFlowStep,
    location: Location
) => getPlacedBidAnalytics(state, itemId, bidAmount, AnalyticsTypes.Track.PlaceAbsenteeBid, 'absentee', location, step);

export const getPlacedLiveBidAnalytics = (state: GlobalState, itemId: number, bidAmount: number, location: Location) =>
    getPlacedBidAnalytics(state, itemId, bidAmount, AnalyticsTypes.Track.PlaceLiveBid, 'live', location);

export const getRetractedAbsenteeBidAnalytics = (
    state: GlobalState,
    itemId: number,
    retractionReason: string,
    retractionExplanation: string
) => {
    const userData = getUserData(state);
    const bidderId = userData.bidderId;

    return {
        analytics: {
            eventPayload: {
                event: AnalyticsTypes.Track.RetractAbsenteeBid,
                properties: {
                    bidderId,
                    itemId,
                    retractionExplanation,
                    retractionReason,
                },
            },
            eventType: EventTypes.track,
        },
    };
};

/* ACTION CREATORS */
const sailthruTrack = (url) => {
    const sailthru = getSailthru();
    if (sailthru) {
        const path = separateURL(url).path ? separateURL(url).path : url;
        const finalURL = `https://www.liveauctioneers.com${path}`;
        try {
            sailthru.track('pageview', {
                url: finalURL,
            });
        } catch (e) {
            // commenting out this error because its pointless
        }
    }
};

const trackPageView =
    (pageType: string, props: any = {}, location: Location, baseUrl?: string) =>
    async (dispatch: AppDispatch) => {
        const currentUrl = buildUrlFromLocation(location, baseUrl);

        sailthruTrack(currentUrl);

        const trackPageViewAction: TRACK_PAGEVIEW_ACTION = {
            error: false,
            meta: {
                actionTime: Date.now(),
                analytics: {
                    eventPayload: {
                        name: pageType,
                        properties: {
                            pageType,
                            url: currentUrl,
                            ...props,
                        },
                    },
                    eventType: EventTypes.page,
                },
            },
            payload: {},
            type: TRACK_PAGEVIEW,
        };

        return dispatch(trackPageViewAction);
    };

export const recordAnalyticsForAboutUsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.AboutUs, null, location));

export const recordAnalyticsForAccountSettingsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.AccountSettings, null, location));

export const recordAnalyticsForAddCreditCardPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.AddCreditCard, null, location));

export const recordAnalyticsForAuctionResultsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.AuctionResults, null, location));

export const recordAnalyticsForBidConsolePageView =
    ({ catalogId, location }: { catalogId: number; location: Location }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const catalogStatus = getCatalogStatus(state, catalogId);
        const sellerId = getCatalogSellerId(state, catalogId);
        const saleStartTs = getCatalogStartTime(state, catalogId);
        const title = getCatalogTitle(state, catalogId);

        return dispatch(
            trackPageView(
                AnalyticsTypes.Page.BiddingConsole,
                {
                    catalogId,
                    catalogStatus,
                    houseId: sellerId,
                    saleStartTs,
                    title,
                },
                location
            )
        );
    };

export const recordAnalyticsForBidDiscoverPage =
    ({ location, topic }: { location: Location; topic: string }) =>
    async (dispatch: AppDispatch) => {
        return dispatch(trackPageView(AnalyticsTypes.Page.Discover, { topic }, location));
    };

export const recordAnalyticsForCatalogPageView =
    ({ catalogId, location }: { catalogId: number; location: Location }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const catalogStatus = getCatalogStatus(state, catalogId);
        const sellerId = getCatalogSellerId(state, catalogId);
        const saleStartTs = getCatalogStartTime(state, catalogId);
        const title = getCatalogTitle(state, catalogId);

        const topRatedHouse = isTopRatedHouse(state, sellerId);

        return dispatch(
            trackPageView(
                AnalyticsTypes.Page.Catalog,
                {
                    catalogId,
                    catalogStatus,
                    houseId: sellerId,
                    saleStartTs,
                    title,
                    topRatedHouse,
                },
                location
            )
        );
    };

export const recordAnalyticsForAuctionCalendarPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.AuctionCalendar, null, location));

export const recordAnalyticsForCategoryLandingPageView =
    ({ location, pageType }: { location: Location; pageType: string }) =>
    async (dispatch: AppDispatch) =>
        dispatch(trackPageView(pageType, null, location));

export const recordAnalyticsForCheckoutConfirmationPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.CheckoutConfirmation, null, location));

export const recordAnalyticsForCheckoutPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Checkout, null, location));

export const recordAnalyticsForConsignmentsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Consignments, null, location));

export const recordAnalyticsForCookiePolicyPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.CookiePolicy, null, location));

export const recordAnalyticsForCuratedCollectionPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.CuratedCollection, null, location));

export const recordAnalyticsForFeaturesPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Features, null, location));

export const recordAnalyticsForHomePageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Home, null, location));

export const recordAnalyticsForHowAuctionsWorkPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.HowAuctionsWork, null, location));

export const recordAnalyticsForIdentify =
    (customBidder?: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const resellerType = getResellerType(state);
        const bidder = customBidder || getUserData(state);
        const isLoggedIn = isUserLoggedIn(state);

        // If user is logged in, identify with Segment
        if (isLoggedIn) {
            const action: IDENTIFY_ANALYTICS_ACTION = {
                error: false,
                meta: getLoginAnalytics(bidder, resellerType),
                payload: {},
                type: IDENTIFY_ANALYTICS,
            };
            return dispatch(action);
        }
    };

export const recordAnalyticsForIntellectualPropertyPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.IntellectualProperty, null, location));

export const recordAnalyticsForItemPageView =
    ({ itemId, location }: { itemId: number; location: Location }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const { catalogId, leadingBid, salePrice, sellerId, title } = getItemSummary(state, itemId);
        const catalogStatus = getCatalogStatus(state, catalogId);
        const buyersPremium = getCatalogBuyersPremium(state, catalogId);
        const facets = getItemFacets(state, itemId);

        const { categories, creators, materialsTechniques, origins, stylePeriods } = facets;

        const pageType = catalogStatus === AuctionStatus.Done ? AnalyticsTypes.Page.SoldItem : AnalyticsTypes.Page.Item;

        const topRatedHouse = isTopRatedHouse(state, sellerId);

        return dispatch(
            trackPageView(
                pageType,
                {
                    buyersPremium,
                    catalogId,
                    catalogStatus,
                    categories,
                    creators,
                    houseId: sellerId,
                    itemId,
                    materialsTechniques,
                    origins,
                    salePrice: salePrice || leadingBid,
                    stylePeriods,
                    title,
                    topRatedHouse,
                },
                location
            )
        );
    };

export const recordAnalyticsForPriceResultPageView =
    ({ itemId, location }: { itemId: number; location: Location }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const title = getItemModelTitle(state, itemId);
        const pageType = AnalyticsTypes.Page.PriceResult;

        return dispatch(
            trackPageView(
                pageType,
                {
                    title,
                },
                location
            )
        );
    };

export const recordAnalyticsForMessengerPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Message, null, location));

export const recordAnalyticsForMessagesPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Messages, null, location));

export const recordAnalyticsForMyAuctionsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.MyAuctions, null, location));

const recordAnalyticsForMyBidsUpcomingPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView('my bids - upcoming tab', null, location));

const recordAnalyticsForMyBidsPastPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView('my bids - past tab', null, location));

export const recordAnalyticsForMyBidsPageView =
    ({ location, tab }: { location: Location; tab: UserBidPlacedItemsStatusEnum }) =>
    async (dispatch: AppDispatch) => {
        if (tab === UserBidPlacedItemsStatusEnum.UPCOMING) {
            return dispatch(recordAnalyticsForMyBidsUpcomingPageView(location));
        } else if (UserBidPlacedItemsStatusEnum.ENDED) {
            return dispatch(recordAnalyticsForMyBidsPastPageView(location));
        }
    };

export const recordAnalyticsForYouPageNewItems = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView('For You - New Items', null, location));

export const recordAnalyticsForYouPageMySearches = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView('For You - My Searches', null, location));

export const recordAnalyticsForYouPageMyAuctioneers = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView('For You - My Auctioneers', null, location));

export const recordAnalyticsForNearMePageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.NearMe, null, location));

export const recordAnalyticsForNotificationsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.NotificationsPage, null, location));

export const recordAnalyticsForPasswordResetPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.PasswordReset, null, location));

export const recordAnalyticsForPrivacyPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Privacy, null, location));

export const recordAnalyticsForPRServicesPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.PRServices, null, location));

export const recordAnalyticsForRecentItemsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.RecentItems, null, location));

export const recordAnalyticsForRecommendedItemsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.RecommendedItems, null, location));

export const recordAnalyticsForReviewPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Review, null, location));

export const recordAnalyticsForSavedItemsPage = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.SavedItems, null, location));

export const recordAnalyticsForSellerDirectoryPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.SellerDirectory, null, location));

export const recordAnalyticsForSellerTestimonialsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.SellerTestimonials, null, location));

export const recordAnalyticsForSellerPageView =
    ({ location, sellerId }: { location: Location; sellerId: number }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const name = getSellerName(state, sellerId);
        const topRatedHouse = isTopRatedHouse(state, sellerId);

        return dispatch(
            trackPageView(
                AnalyticsTypes.Page.Seller,
                {
                    houseId: sellerId,
                    name,
                    topRatedHouse,
                },
                location
            )
        );
    };

export const recordAnalyticsForStorefrontPageView =
    ({ houseId, location }: { houseId: number; location: Location }) =>
    async (dispatch: AppDispatch) =>
        dispatch(trackPageView(AnalyticsTypes.Page.Storefront, { houseId }, location));

export const recordAnalyticsForSellerReviewsPageView =
    ({ houseId, location }: { houseId: number; location: Location }) =>
    async (dispatch: AppDispatch) =>
        dispatch(trackPageView(AnalyticsTypes.Page.SellerReviews, { houseId }, location));

export const recordAnalyticsForSearchItemsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.SearchItems, null, location));

export const recordAnalyticsForSimilarItemsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.SimilarItems, null, location));

export const recordAnalyticsForACHTermsAndConditionsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.ACHTermsAndConditions, null, location));

export const recordAnalyticsForTermsAndConditionsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.TermsAndConditions, null, location));

export const recordAnalyticsForTermsAndConditionsPaymentsPageView =
    (location: Location) => async (dispatch: AppDispatch) =>
        dispatch(trackPageView(AnalyticsTypes.Page.TermsAndConditionsPayments, null, location));

export const recordAnalyticsForCatalogTermsPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.CatalogTerms, null, location));

export const recordAnalyticsForTerminologyPageView = (location: Location) => async (dispatch: AppDispatch) =>
    dispatch(trackPageView(AnalyticsTypes.Page.Terminology, null, location));

export const recordAnalyticsForWonItemsPageView = (location: Location) => async (dispatch: AppDispatch) => {
    dispatch(trackPageView(AnalyticsTypes.Page.WonItems, null, location));
};

type CertCaptureAnalyticsProps = {
    bidderId: number;
    state: string;
};
export const recordAnalyticsForCertCapturePageView =
    ({ location, props }: { location: Location; props: CertCaptureAnalyticsProps }) =>
    async (dispatch: AppDispatch) => {
        dispatch(trackPageView(AnalyticsTypes.Page.CertCapture, props, location));
    };

const getGAEventAnalytics = (action: string, category?: string, label?: string) => {
    return {
        analytics: {
            eventPayload: {
                event: action,
                properties: {
                    action,
                    category,
                    label,
                },
            },
            eventType: EventTypes.track,
        },
    };
};

const getGAEventAnalyticsExtraProps = (action: string, category: string, extraProps: object = {}) => {
    return {
        analytics: {
            eventPayload: {
                event: action,
                properties: {
                    ...extraProps,
                    action,
                    category,
                },
            },
            eventType: EventTypes.track,
        },
    };
};

const getGAEventAnalyticsForBuyNowOffer = (properties) => {
    return {
        analytics: {
            eventPayload: {
                event: properties.action,
                properties,
            },
            eventType: EventTypes.track,
        },
    };
};

export const trackClick = (action: string, label: string) => async (dispatch: AppDispatch) => {
    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackAdUnitClick =
    ({
        adUnitId,
        catalogId,
        consignment,
        page,
        sellerId,
    }: {
        adUnitId: number;
        catalogId?: number;
        consignment: boolean;
        page: string;
        sellerId: number;
    }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);

        // Call the marketing-reporting-service lambda to record the click
        if (page === 'calendar' && !Boolean(consignment)) {
            // Fire off Auction Calendar Banner Ad clicked
            recordMarketingReportsClick({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Auction Calendar Banner',
            });
        } else if (page === 'navigation') {
            // Fire off Category Navigation Ad clicked
            recordMarketingReportsClick({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Category Navigation Ad',
            });
        } else if (page.includes('Category Leaderboard Ad')) {
            // Fire off Category Banner Ad clicked
            recordMarketingReportsClick({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: page,
            });
        } else if (page.includes('Search Page Banner')) {
            // Fire off Search Page Banner Ad clicked
            recordMarketingReportsClick({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: page,
            });
        } else if (page.includes('Leaderboard Ad')) {
            // Fire off Category Navigation Ad clicked
            recordMarketingReportsClick({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: page,
            });
        } else if (page.includes('home')) {
            // Fire off Featured Auctioneers Ad viewed
            recordMarketingReportsClick({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Featured Auctioneers Ad',
            });
        } else if (!Boolean(consignment)) {
            // Fire off Category Banner Ad clicked
            recordMarketingReportsClick({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Category Banner',
            });
        }

        let action = `Clicked ${catalogId ? 'Catalog' : 'Consignmet'} Ad Unit`;
        action = page === 'home' ? 'Featured Auctioneers Ad Unit' : action;
        let label = `sideBanner-${adUnitId}-${catalogId || sellerId}`;
        label = page === 'home' ? `homepage-${adUnitId}-${sellerId}` : label;

        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackAdUnitVisible =
    ({
        adUnitId,
        catalogId,
        consignment,
        page,
        sellerId,
    }: {
        adUnitId: number;
        catalogId?: number;
        consignment?: boolean;
        page: string;
        sellerId?: number;
    }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);

        // Call the marketing-reporting-service lambda to record the impression
        if (page === 'calendar') {
            // Fire off Auction Calendar Banner Ad viewed
            recordMarketingReportsImpression({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Auction Calendar Banner',
            });
        } else if (page === 'navigation') {
            // Fire off Category Navigation Ad visible
            recordMarketingReportsImpression({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Category Navigation Ad',
            });
        } else if (page.includes('Category Leaderboard Ad')) {
            // Fire off Category Banner Ad visible
            recordMarketingReportsImpression({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: page,
            });
        } else if (page.includes('Search Page Banner')) {
            // Fire off Search Page Banner Ad impression
            recordMarketingReportsImpression({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Search Page Banner',
            });
        } else if (page.includes('Leaderboard Ad')) {
            // Fire off Category Navigation Ad visible
            recordMarketingReportsImpression({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: page,
            });
        } else if (page.includes('home')) {
            // Fire off Featured Auctioneers Ad viewed
            recordMarketingReportsImpression({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Featured Auctioneers Ad',
            });
        } else {
            // Fire off Category Banner Ad viewed
            recordMarketingReportsImpression({
                bidderId,
                deployment,
                eventId: adUnitId,
                eventType: 'Category Banner',
            });
        }

        const label: {
            adUnitId: number;
            bidderId?: number;
            catalogId?: number;
            eventType: string;
            sellerId?: number;
        } = {
            adUnitId,
            eventType: consignment ? 'Consignment Ad' : 'Catalog Ad',
        };

        if (page === 'home') {
            label.eventType = 'Featured Auctioneers Ad';
        }

        if (bidderId && bidderId > 0) {
            label.bidderId = bidderId;
        }
        if (catalogId) {
            label.catalogId = catalogId;
        }
        if (sellerId) {
            label.sellerId = sellerId;
        }

        const analytics = getGAEventAnalyticsExtraProps('Promotion Viewed', 'Navigation', label);
        // @ts-ignore
        analytics.analytics.eventPayload.properties.nonInteraction = 1;

        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_VIEW,
        } as TRACK_VIEW_ACTION);
    };

/**
 * trackLoginSignupModalViewed - tracking event that requires
 * a configured modalContext to work effectively
 * @see WWF-123
 * @param ModalContext
 * @returns Promise<Dispatch>
 */
export const trackLoginSignupModalViewed =
    (modalContext: ModalContext) => (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const { itemId } = modalContext;
        const catalogId = itemId ? getItemModelCatalogId(state, itemId) : modalContext.catalogId || undefined;
        const houseId = itemId ? getItemModelSellerId(state, itemId) : modalContext.sellerId || undefined;

        const extraProps: {
            catalogId: number;
            context: string;
            eventType: string;
            houseId: number;
            itemId?: number;
        } = {
            catalogId,
            context: modalContext.eventContext,
            eventType: modalContext.eventName as string,
            houseId,
            itemId: itemId || undefined,
        };

        const analytics = getGAEventAnalyticsExtraProps(extraProps.eventType, 'Navigation', extraProps);

        const action: TRACK_VIEW_ACTION = {
            error: false,
            meta: { ...analytics },
            payload: {},
            type: TRACK_VIEW,
        };

        return dispatch(action);
    };

export const trackPromotedItemVisible =
    ({ itemId }: { itemId: number }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);
        const { catalogId, sellerId } = getItemSummary(state, itemId);
        const deployment = getDeployment(state);

        // Call the marketing-reporting-service lambda to record the impression
        recordMarketingReportsImpression({
            bidderId,
            deployment,
            eventId: catalogId,
            eventType: 'Featured Lots',
        });

        const label: {
            bidderId?: number;
            catalogId?: number;
            eventType: string;
            itemId: number;
            sellerId?: number;
        } = {
            eventType: 'Featured Lot',
            itemId,
        };

        if (bidderId > 0) {
            label.bidderId = bidderId;
        }
        if (catalogId) {
            label.catalogId = catalogId;
        }
        if (sellerId) {
            label.sellerId = sellerId;
        }

        const analytics = getGAEventAnalyticsExtraProps('Promotion Viewed', 'Navigation', label);
        // @ts-ignore
        analytics.analytics.eventPayload.properties.nonInteraction = 1;

        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_VIEW,
        } as TRACK_VIEW_ACTION);
    };

export const trackACNClick = (label: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage ACN Module';
    let labelToUse = `${label}`;
    if (bidderId > 0) {
        labelToUse += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', labelToUse);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackHeroClick = (label: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Hero Banner Module';
    let labelToUse = `${label}`;
    if (bidderId > 0) {
        labelToUse += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', labelToUse);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackFeaturedCatalogView =
    ({ catalogId }: { catalogId: number }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const sellerId = getCatalogSellerId(state, catalogId);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);

        // Call the marketing-reporting-service lambda to record the impression
        recordMarketingReportsImpression({
            bidderId,
            deployment,
            eventId: catalogId,
            eventType: 'Featured Auction',
        });

        const label: {
            bidderId?: number;
            catalogId: number;
            eventType: string;
            sellerId?: number;
        } = {
            catalogId,
            eventType: 'Homepage Featured Catalog',
        };

        if (bidderId > 0) {
            label.bidderId = bidderId;
        }

        if (sellerId) {
            label.sellerId = sellerId;
        }

        const analytics = getGAEventAnalyticsExtraProps('Promotion Viewed', 'Navigation', label);
        // @ts-ignore
        analytics.analytics.eventPayload.properties.nonInteraction = 1;

        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_VIEW,
        } as TRACK_VIEW_ACTION);
    };

export const trackAuctionRegistration =
    ({
        catalogId,
        context = EventContext.registerForAuction,
        eventType = AnalyticsTypes.Track.StartedAuctionRegistration,
        itemId,
    }: {
        catalogId: number;
        context?: string;
        eventType?: string;
        itemId?: number;
    }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const houseId = getCatalogSellerId(state, catalogId);
        const whiteLabel = getIsWhiteLabel(state);
        const catalogStatus = getCatalogStatus(state, catalogId);
        const isCatalogOnly = getCatalogIsCatalogOnly(state, catalogId);
        const topRatedHouse = isTopRatedHouse(state, houseId);
        const autopay = getCatalogIsAutopay(state, catalogId);
        // getCatalogIsLivePayments: comes back undefined sometimes
        const livePayments = Boolean(getCatalogIsLivePayments(state, catalogId));
        const bidderId = getBidderId(state);

        const extraProps: {
            autopay: boolean;
            bidderId: number;
            catalogId: number;
            catalogStatus: string;
            context: string;
            eventType: string;
            houseId: number;
            isCatalogOnly: boolean;
            itemId?: number;
            livePayments: boolean;
            topRatedHouse: boolean;
            whiteLabel: boolean;
        } = {
            autopay,
            bidderId,
            catalogId,
            catalogStatus,
            context,
            eventType,
            houseId,
            isCatalogOnly,
            itemId,
            livePayments,
            topRatedHouse,
            whiteLabel,
        };

        const analytics = getGAEventAnalyticsExtraProps(extraProps.eventType, 'Registration', extraProps);

        const action: TRACK_VIEW_ACTION = {
            error: false,
            meta: { ...analytics },
            payload: {},
            type: TRACK_VIEW,
        };

        return dispatch(action);
    };

export const trackFeaturedCatalogClick =
    (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const sellerId = getCatalogSellerId(state, catalogId);
        const bidderId = getBidderId(state);
        const deployment = getDeployment(state);

        // Call the marketing-reporting-service lambda to record the click
        recordMarketingReportsClick({
            bidderId,
            deployment,
            eventId: catalogId,
            eventType: 'Featured Auction',
        });

        const action = 'Clicked Homepage Featured Auctions Module';
        let label = `featured-${catalogId}-${sellerId}`;
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackPersonalizeBannerClick = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Personalize Banner';
    let label = `personalize-banner-bidder-${bidderId}`;

    const analytics = getGAEventAnalytics(action, 'Personalize', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackLiveAuctionBarClick = (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const sellerId = getCatalogSellerId(state, catalogId);
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Live Auction Module';
    let label = `live-auction-bar-${catalogId}-${sellerId}`;
    if (bidderId > 0) {
        label += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackFeaturedCategoryClick = (label: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Featured Categories Module';
    let labelToUse = `${label}`;
    if (bidderId > 0) {
        labelToUse += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', labelToUse);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackRecentItemClick = (itemId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { sellerId } = getItemSummary(state, itemId);
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Recent Items Module';
    let label = `Recent-Items-${itemId}-${sellerId}`;
    if (bidderId > 0) {
        label += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackRecommendedItemClick = (itemId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { sellerId } = getItemSummary(state, itemId);
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Recommended Items Module';
    let label = `Recommended-Items-${itemId}-${sellerId}`;
    if (bidderId > 0) {
        label += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackFromFollowedSearchClick =
    (itemId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);

        const action = 'Clicked Homepage Followed Search Module';
        let label = `followed-search-${itemId}`;
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackFromSearchFollowSuggestionClick =
    ({ categoryId, keyword }: { categoryId: any; keyword: string }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);

        const action = 'Clicked Homepage Search Follow Suggestions Follow Button';
        let label = 'search-follow-suggestion';
        if (categoryId > 0) {
            label += `-${categoryId}`;
        }
        if (keyword !== undefined && keyword.length > 0) {
            label += `-${keyword}`;
        }
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackItemFacetLinkClicked =
    ({ facetName, facetParent }: { facetName: string; facetParent: string }) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);

        const action = 'Facet Link Clicked';
        let label = 'itemFacet';
        if (facetName.trim().length > 0) {
            label += `-${kebabCase(facetName.toLowerCase())}`;
        }
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const extraProps = { bidderId, facetName, facetParent, label };
        const analytics = getGAEventAnalyticsExtraProps(action, 'Navigation', extraProps);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackPastAuctionResultsClick = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);

    const action = 'Clicked Past Auction Results';
    let label = 'Past-Auction-Results';
    if (bidderId > 0) {
        label += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackPriceResultsClick = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);

    const action = 'Clicked Price Results';
    let label = 'Price-Results';
    if (bidderId > 0) {
        label += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackTrendingItemClick = (itemId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { sellerId } = getItemSummary(state, itemId);
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Trending Items Module';
    let label = `Trending-Items-${itemId}-${sellerId}`;
    if (bidderId > 0) {
        label += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackUpcomingCatalogClick =
    (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const catalogStatus = getCatalogStatus(state, catalogId);
        const sellerId = getCatalogSellerId(state, catalogId);
        const bidderId = getBidderId(state);
        const status = catalogStatus === 'online' ? 'upcoming' : catalogStatus;

        const action = 'Clicked Homepage Live and Upcoming Auctions Module';
        let label = `${status}-${catalogId}-${sellerId}`;
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackAuctionsYouMayLikeClick =
    (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const catalogStatus = getCatalogStatus(state, catalogId);
        const sellerId = getCatalogSellerId(state, catalogId);
        const bidderId = getBidderId(state);
        const status = catalogStatus === 'online' ? 'upcoming' : catalogStatus;

        const action = 'Clicked Homepage Auctions You May Like Module';
        let label = `${status}-${catalogId}-${sellerId}`;
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackCatalogsNearCarouselClick =
    (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const catalogStatus = getCatalogStatus(state, catalogId);
        const sellerId = getCatalogSellerId(state, catalogId);
        const bidderId = getBidderId(state);
        const status = catalogStatus === 'online' ? 'upcoming' : catalogStatus;

        const action = 'Clicked Homepage Auctions Near Module';
        let label = `${status}-${catalogId}-${sellerId}`;
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackAddToCalendarClick =
    (catalogId: number, calendarType: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const sellerId = getCatalogSellerId(state, catalogId);
        const bidderId = getBidderId(state);

        const action = 'Clicked Add to Calendar';
        let label = `${calendarType}-${catalogId}-${sellerId}`;
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackUserAuthenticated = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);
    dispatch({
        meta: {
            analytics: {
                eventPayload: {
                    event: AnalyticsTypes.Track.UserAuthenticated,
                    properties: {
                        bidderId,
                        nonInteraction: 1,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_USER_AUTHENTICATED_DATA,
    } as TRACK_USER_AUTHENTICATED_DATA_ACTION);
};

export const trackPlacedSearch = (keyword: string) => async (dispatch: AppDispatch) => {
    const action: TrackPlacedSearchAction = {
        error: false,
        meta: {
            analytics: {
                eventPayload: {
                    event: AnalyticsTypes.Track.PlaceSearch,
                    properties: {
                        keyword,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_PLACED_SEARCH,
    };
    return dispatch(action);
};

export const trackSendSellerMessage =
    ({ catalogId, houseId, itemId }: { catalogId: number; houseId: number; itemId: number }) =>
    async (dispatch: AppDispatch) => {
        const action: TrackSendSellerMessageAction = {
            error: false,
            meta: {
                analytics: {
                    eventPayload: {
                        event: AnalyticsTypes.Track.SendSellerMessage,
                        properties: {
                            catalogId,
                            houseId,
                            itemId,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: {},
            type: TRACK_SEND_SELLER_MESSAGE,
        };
        return dispatch(action);
    };

export const trackSendSupportMessage =
    ({ catalogId, itemId }: { catalogId: number; itemId: number }) =>
    async (dispatch: AppDispatch) => {
        const action: TrackSendSupportMessageAction = {
            error: false,
            meta: {
                analytics: {
                    eventPayload: {
                        event: AnalyticsTypes.Track.SendSupportMessage,
                        properties: {
                            catalogId,
                            itemId,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: {},
            type: TrackSendSupportMessage,
        };
        return dispatch(action);
    };

export const trackOnboardingWalkthrough =
    (event: keyof typeof AnalyticsTypes.Track.OnboardingWalkthrough, label: string) =>
    async (dispatch: AppDispatch) => {
        const action: TrackOnboardingWalkthroughAction = {
            error: false,
            meta: {
                analytics: {
                    eventPayload: {
                        event: AnalyticsTypes.Track.OnboardingWalkthrough[event],
                        properties: {
                            category: 'Onboarding',
                            label,
                            nonInteraction: event === 'ViewedOnboardingTooltip' ? 1 : undefined,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: {},
            type: TRACK_ONBOARDING_WALKTHROUGH,
        };
        return dispatch(action);
    };

export const trackTwoFactorAuthentication = (event: TrackTwoFactorAuthentication) => async (dispatch: AppDispatch) => {
    const action: TrackTwoFactorAuthenticationAction = {
        error: false,
        meta: {
            analytics: {
                eventPayload: {
                    event,
                    properties: {
                        category: '2FA',
                        label: event,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_TWO_FACTOR_AUTHENTICATION,
    };
    return dispatch(action);
};

export const trackTwoFactorAuthenticationTryAnotherWay =
    (event: TrackTwoFactorAuthenticationTryAnotherWay) => async (dispatch: AppDispatch) => {
        const action: TrackTwoFactorAuthenticationTryAnotherWayAction = {
            error: false,
            meta: {
                analytics: {
                    eventPayload: {
                        event,
                        properties: {
                            category: '2FA',
                            label: event,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: undefined,
            type: TRACK_TWO_FACTOR_AUTHENTICATION_TRY_ANOTHER_WAY,
        };
        return dispatch(action);
    };

export const recordAttributionAnalytics = (data: any) => {
    const attribution: TRACK_ATTRIBUTION_DATA_ACTION = {
        error: false,
        meta: {
            analytics: {
                eventPayload: {
                    properties: {
                        attribution_campaign: data.campaign,
                        attribution_content: data.content,
                        attribution_keyword: data.keyword,
                        attribution_medium: data.medium,
                        attribution_source: data.source,
                        attribution_touchdate: data.touchDate,
                        nonInteraction: 1,
                        should_store_in_db: true,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_ATTRIBUTION_DATA,
    };

    if (data.bidderId > 0) {
        // @ts-ignore
        attribution.meta.analytics.eventPayload.properties.attribution_bidderId = data.bidderId;
    }

    return attribution;
};

export const recordSocialNetworkShare =
    (network: string, itemUrl: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);

        trackShareButtonClickedAnalytics({
            itemUrl,
            network,
            pageName: getAmplitudePageNameFromPath(window.location.pathname),
            url: window.location.href,
        });

        dispatch({
            meta: {
                analytics: {
                    eventPayload: {
                        event: AnalyticsTypes.Track.ShareItem,
                        properties: {
                            bidderId,
                            itemUrl,
                            network,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            payload: {},
            type: TRACK_SOCIAL_NETWORK_SHARE,
        } as TRACK_SOCIAL_NETWORK_SHARE_ACTION);
    };

export const trackComingUpItemsClick = (itemId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    let { sellerId } = getItemSummary(state, itemId);
    const bidderId = getBidderId(state);

    const action = 'Clicked Homepage Coming Up Items Module';
    let label = `Coming-Up-${itemId}-${sellerId}`;
    if (bidderId > 0) {
        label += `-${bidderId}`;
    }

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackZeroSearchResultsShelfClick =
    (shelfId: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const bidderId = getBidderId(state);

        const action = 'Clicked Zero Results Suggestion Shelf';
        let label = `ZeroResults ${shelfId} shelf`;
        if (bidderId > 0) {
            label += `-${bidderId}`;
        }

        const analytics = getGAEventAnalytics(action, 'Navigation', label);
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export const trackDesktopNotificationsView = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const bidderId = getBidderId(state);

    const action = 'Viewed notifications on desktop';
    const label = `Viewed-Notifications-${bidderId}`;

    const analytics = getGAEventAnalytics(action, 'Navigation', label);
    return dispatch({
        meta: { ...analytics },
        payload: {},
        type: TRACK_CLICK,
    } as TRACK_CLICK_ACTION);
};

export const trackConversionForAlgolia = (itemId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const deployment = getDeployment(state);
    const bidderId = getBidderId(state);
    const ipAddress = getClientIpAddress(state);
    const algoliaAnalytics = getAlgoliaAnalytics(state);

    if (!algoliaAnalytics || !algoliaAnalytics.queryId) {
        return;
    }

    await algoliaApi.trackConversion({
        algoliaAnalytics,
        bidderId,
        deployment,
        ipAddress,
        itemId,
    });
    return dispatch({
        payload: {
            itemId,
        },
        type: TRACK_CONVERSION_FOR_ALGOLIA,
    } as TRACK_CONVERSION_FOR_ALGOLIA_ACTION);
};

export const trackClickForAlgolia =
    (itemId: number, position: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const ipAddress = getClientIpAddress(state);
        const { catalogId } = getItemSummary(state, itemId);
        const algoliaAnalytics = getAlgoliaAnalytics(state);

        // Call the marketing-reporting-service lambda to record the click
        recordMarketingReportsClick({
            bidderId,
            deployment,
            eventId: catalogId,
            eventType: 'Featured Lots',
        });

        if (!algoliaAnalytics || !algoliaAnalytics.queryId) {
            return;
        }

        await algoliaApi.trackClick({
            algoliaAnalytics,
            bidderId,
            deployment,
            ipAddress,
            itemId,
            position,
        });

        return dispatch({
            payload: {
                itemId,
                position,
            },
            type: TRACK_CLICK_FOR_ALGOLIA,
        } as TRACK_CLICK_FOR_ALGOLIA_ACTION);
    };

export const trackBuyNowOfferSubmissionClick =
    (itemId: number, buyNowPrice: number, offerPrice: number, currency: string) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const { catalogId, sellerId } = getItemSummary(state, itemId);
        const topRatedHouse = isTopRatedHouse(state, sellerId);

        let action;

        switch (state.makeOffer.modalType) {
            case modalTypes.MAKE_OFFER:
                action = 'Made An Offer';
                break;
            case modalTypes.BUY_NOW:
                action = 'Requested To Buy Now';
                break;
            default:
                break;
        }

        const analytics = getGAEventAnalyticsForBuyNowOffer({
            action,
            bidderId,
            catalogId,
            currency,
            houseId: sellerId,
            itemId,
            offerPrice,
            topRatedHouse,
            value: offerPrice,
        });

        const details = {
            buyNowPrice,
            buyNowRequestType: action,
            houseId: sellerId,
            itemId,
            offerPrice,
        };

        api.postCaptureBuyNowRequest({ authToken, deployment, details });
        return dispatch({
            meta: { ...analytics },
            payload: {},
            type: TRACK_CLICK,
        } as TRACK_CLICK_ACTION);
    };

export type TrackAbandonedModalParams = {
    bidderId: number;
    catalogId: number;
    houseId: number;
    lotId: number;
    modalName:
        | 'Absentee Bid Modal'
        | 'Registration Modal'
        | 'Add Payment Modal - Registration'
        | 'Add Payment Modal - Account Settings'
        | 'Confirm Payment Modal'
        | 'Update Address Modal'
        | AuthModalPages;
};

export const trackAbandonedModal = ({ bidderId, catalogId, houseId, lotId, modalName }: TrackAbandonedModalParams) => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Abandoned Modal',
                properties: {
                    bidderId,
                    catalogId,
                    houseId,
                    lotId,
                    modalName,
                },
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TRACK_ABANDONED_MODAL,
});

export enum AbsenteeBidFlowStep {
    addPaymentViewed = 'Payment Method Modal Viewed',
    addedPaymentMethod = 'Added Payment Method',
    addedShippingAddress = 'Added Shipping Address',
    addressVerified = 'Shipping Address Verified',
    outbidViewed = 'Outbid Modal Viewed',
    placedAbsenteeBid = 'Placed Absentee Bid',
    raisedAbsenteeBid = 'Raised Absentee Bid',
    reviewBidCompleted = 'Review Absentee Bid Completed',
    reviewBidViewed = 'Review Absentee Bid Modal Viewed',
    selectBidAmountViewed = 'Select Bid Amount Viewed',
    selectedBidAmount = 'Selected Bid Amount',
    shippingAddressViewed = 'Shipping Address Modal Viewed',
    startedAbsenteeBid = 'Started Absentee Bid',
    verifyAddressViewed = 'Verify Shipping Address Modal Viewed',
}

export type TrackAbsenteeBidFlowStepParams = {
    bidderId?: number;
    deviceType: string;
    itemId?: number;
    mobile: string;
    step: AbsenteeBidFlowStep;
};

export const trackAbsenteeBidFlowStep = ({
    bidderId,
    deviceType,
    itemId,
    mobile,
    step,
}: TrackAbsenteeBidFlowStepParams) => {
    const absenteeBidAnalytics = {
        meta: {
            analytics: {
                eventPayload: {
                    event: step,
                    properties: {
                        bidderId,
                        deviceType,
                        itemId,
                        mobile,
                        step,
                    },
                },
                eventType: EventTypes.track,
            },
        },
        payload: {},
        type: TRACK_ABSENTEE_BID_FLOW_STEP,
    };

    return absenteeBidAnalytics;
};

export enum ItemListNames {
    CatalogPage = 'Catalog Page',
    CategoryPage = 'Category Page',
    ComingUpForAuction = 'Coming Up For Auction',
    DiscoverPage = 'Discover Page',
    ForYouPage = 'For You Page',
    MoreFromAuction = 'More From Auction',
    MoreInCategory = 'More In Category',
    MyBids = 'My Bids',
    NewFromFollowedSearches = 'New From Followed Searches',
    RecentlyViewed = 'Recently Viewed',
    RecommendedCategory = 'Recommended Category',
    RecommenedItems = 'Recommended Items',
    RecordResults = 'Record Results',
    RelatedCategory = 'Related Category',
    RelatedItems = 'Related Items',
    SavedItems = 'Saved Items',
    SearchPage = 'Search Page',
    SimilarItems = 'Similar Items',
}

export type TrackProductClickedProperties = {
    catalogId: number;
    catalogStatus: string;
    currency: string;
    houseId: number;
    itemId: number;
    itemListName: ItemListNames;
    price: number;
    quantity?: number;
    title: string;
};

export const trackProductClicked = ({
    catalogId,
    catalogStatus,
    currency,
    houseId,
    itemId,
    itemListName,
    price,
    quantity = 1,
    title,
}: TrackProductClickedProperties) => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Product Clicked',
                properties: {
                    catalogId,
                    catalogStatus,
                    currency,
                    houseId,
                    itemId,
                    itemListName,
                    price,
                    quantity,
                    title,
                },
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TrackProductClicked,
});

export const trackGetHelpViewed = () => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Get Help Viewed',
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TrackGetHelpViewed,
});

export const trackWonItemsPageCheckoutClicked = () => ({
    meta: {
        analytics: {
            eventPayload: {
                event: 'Won Items Page Checkout Clicked',
            },
            eventType: EventTypes.track,
        },
    },
    payload: {},
    type: TrackWonItemsPageCheckoutClicked,
});
