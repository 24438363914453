import { BidderAddress } from '@/types/BidderAddresses';
import { createSelector } from '@reduxjs/toolkit';
import {
    emptyUserData,
    noncontiguousStates,
    USER_SLICE_CACHE_TIME,
    UserRegistrationStatus,
} from '@/redux/modules/account/user/user.types';
import { GlobalState } from '@/redux/store';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import caseInsensitiveCompare from '@liveauctioneers/caterwaul-components/lib/utils/caseInsensitiveCompare';
import Cookies from 'universal-cookie';
import validateAuthToken, { JWTPayload, validateBidderId } from '@/utils/token';

const stateSelector = (state: GlobalState) => state;
const userSelector = createSelector(stateSelector, (state) => state.user);
const passThroughSelector = (_, id: number) => id;

const userDataSelector = createSelector(userSelector, ({ userData }) => userData);
export const getFullBidderData = createSelector(userSelector, (data) => data);

export const getANMS = createSelector(userDataSelector, ({ ANMS }) => ANMS ?? 0);

// we have two here to take advantage of the memoization for the token prop
const getToken = createSelector(userSelector, ({ token }) => token);
export const getAuthToken = createSelector(getToken, (token) => validateAuthToken(token));

export const getBidderEmail = createSelector(userDataSelector, ({ email }) => email || '');

export const getBidderPhoneNumber = createSelector(userDataSelector, (userData) => {
    const { mobileNumber, phoneNumber } = userData;
    return mobileNumber || phoneNumber || '';
});

export const getBidderMobileVerified = createSelector(userDataSelector, (userData): boolean =>
    Boolean(userData?.mobileVerified)
);

export const isTermsOfServiceUnknown = createSelector(
    userDataSelector,
    (userData) => userData?.acceptedTermsOfService === 'unknown'
);

export const getBidderCountry = createSelector(userDataSelector, (userData) => userData?.country ?? '');

export const getBidderHasEverRegisteredForAuction = createSelector(userDataSelector, (userData) =>
    Boolean(userData?.hasEverRegisteredForAuction)
);

export const getBidderHasEverPlacedBid = createSelector(userDataSelector, (userData) =>
    Boolean(userData?.hasEverPlacedBid)
);

export const getBidderState = createSelector(userDataSelector, (userData) => (userData.state ? userData.state : ''));

export const getBidderCity = createSelector(userDataSelector, (userData) => (userData.city ? userData.city : ''));

export const getBidderInUSA = createSelector(
    getBidderCountry,
    (country = '') => caseInsensitiveCompare(country, 'us') || caseInsensitiveCompare(country, 'united states')
);

export const getSignInProviderIsGoogle = createSelector(
    userDataSelector,
    (userData) => userData.signupClient === 'web_google'
);

export const getSignInProviderIsFacebook = createSelector(
    userDataSelector,
    (userData) => userData.signupClient === 'web_facebook'
);
export const getAccountCreatedDate = createSelector(userDataSelector, (userData) =>
    userData.createdDate ? userData.createdDate : null
);

export const isBidderInContiguousUS = createSelector(
    [getBidderCountry, getBidderState],
    (country, state) => ['United States', 'US'].includes(country) && !noncontiguousStates.includes(state)
);

const passthroughTokenSelector = (_: GlobalState, token?: string | null): string => token;
export const getUserRegistrationStatus = createSelector(
    [getAuthToken, passthroughTokenSelector],
    (storedToken, providedToken) => {
        const token = providedToken || storedToken;
        if (token) {
            try {
                // TODO: see if this try catch is enough.  We shouldn't throw if something is wrong
                return jwtDecode<JWTPayload>(token).type;
            } catch (error) {
                return UserRegistrationStatus.Unregistered;
            }
        }
        return UserRegistrationStatus.Unregistered;
    }
);

export const isUserPartiallyRegistered = createSelector(
    getUserRegistrationStatus,
    (bidderType) => bidderType === UserRegistrationStatus.Partially
);

export const isUserVerifiedMinimumBidLimit = createSelector(
    userDataSelector,
    (userData) => userData?.verifiedMinimumBidLimit ?? false
);

const isUserRegistered = createSelector(
    getUserRegistrationStatus,
    (bidderType) => bidderType === UserRegistrationStatus.Registered
);

export const getBidderId = createSelector([userDataSelector, getAuthToken], (userData, token): number => {
    if (!Boolean(userData?.bidderId) || (userData?.bidderId <= 0 && token)) {
        try {
            const decoded = jwtDecode<JWTPayload>(token);

            if (decoded.type === 'bidder') {
                return validateBidderId(Number(decoded.sub));
            }
        } catch (error) {
            console.warn('Failed to decode a potentially valid token', error, token);
        }

        return -1;
    }

    return validateBidderId(userData.bidderId);
});

const cookie = new Cookies();
export const isCookieAuthTokenMismatched = createSelector(
    [getBidderId, getAuthToken],
    (reduxBidderId, reduxAuthToken): boolean => {
        try {
            const cookie = new Cookies();
            const cookieAuthToken = cookie.get('bidder-auth');

            if (cookieAuthToken && reduxAuthToken) {
                const decodedCookieToken = jwtDecode<JWTPayload>(cookieAuthToken);

                if (decodedCookieToken.type === 'bidder') {
                    const cookieBidderId = validateBidderId(Number(decodedCookieToken.sub));

                    // the bidderId from redux + cookie match, everything is fine
                    if (cookieBidderId === reduxBidderId) {
                        return false;
                    }

                    // the bidderIds did not match, something is wrong
                    return true;
                }
            } else {
                return false;
            }
        } catch (error) {
            console.warn('Failed to decode a potentially valid token', error, reduxAuthToken);
            return true;
        }
        return false;
    }
);

export const isUserLoggedIn = createSelector(
    getAuthToken,
    getBidderId,
    isUserRegistered,
    (token, bidderId, registered): boolean => Boolean(token) && registered && bidderId > 0
);

export const isUserAtLeastPartiallyRegistered = createSelector(
    isUserPartiallyRegistered,
    isUserLoggedIn,
    (registered, loggedIn) => registered || loggedIn
);

export const getUserData = createSelector(
    isUserAtLeastPartiallyRegistered,
    userDataSelector,
    (userAtLeastPartiallyRegistered, userData) => (userAtLeastPartiallyRegistered ? userData : emptyUserData)
);

export const getUserCreationDate = createSelector(getUserData, ({ createdDate }) => createdDate);

export const getUserIsInGoodStanding = createSelector(getUserData, (userData) => {
    const standing = userData.standing || '';
    return standing === '' || standing === 'y' || standing === 'good';
});

export const getBidderIsSuspended = createSelector(getUserData, (userData) => {
    const standing = userData.standing || '';
    return standing === 's';
});

export const getUserFirstName = createSelector(getUserData, (userData) => {
    const first = userData.firstName || '';
    return first.trim();
});

export const getUserLastName = createSelector(getUserData, (userData) => {
    const last = userData.lastName || '';
    return last.trim();
});

export const getUserFullName = createSelector(getUserData, (userData) => {
    const { firstName = '', lastName = '' } = userData;
    return `${firstName} ${lastName}`.trim();
});

export const getUserBidWeightPercentile = createSelector(
    getUserData,
    ({ recentBidWeightPercentile }) => recentBidWeightPercentile
);

export const getZipCodeGeoCoordinates = createSelector(getUserData, (userData) =>
    Boolean(userData?.latitude) && Boolean(userData?.longitude)
        ? { latitude: userData.latitude, longitude: userData.longitude }
        : null
);

export const isProfileComplete = createSelector(userDataSelector, (userData) => Boolean(userData?.profileComplete));

const getLoadTimeForUserData = createSelector(userSelector, ({ loaded }) => loaded);

export const isUserDataLoading = createSelector(userSelector, ({ loading }) => loading);

export const isUserDataLoaded = createSelector(userSelector, ({ loaded }) => loaded > 0);

export const getUserAddressById = createSelector(
    userDataSelector,
    passThroughSelector,
    (userData, addressId) => userData?.addresses?.find((address) => address.addressId === addressId) ?? null
);

export const getDefaultAddress = createSelector(userDataSelector, (userData): BidderAddress | null => {
    if (!Boolean(userData.addresses?.length)) {
        return null;
    }
    return userData.addresses.find((address) => address.isDefault) ?? null;
});

export const getDefaultAddressId = createSelector(
    getDefaultAddress,
    (defaultAddress): number => defaultAddress?.addressId ?? 0
);

export const getDefaultAddressIsVerified = createSelector(getDefaultAddress, (defaultAddress): boolean =>
    Boolean(defaultAddress?.validatorProvider)
);

export const getDefaultAddressCountryCode = createSelector(
    [getDefaultAddress],
    (defaultAddress) => defaultAddress?.countryCode ?? ''
);

export const getDefaultAddressPostalCode = createSelector(
    [getDefaultAddress],
    (defaultAddress) => defaultAddress?.postalCode ?? ''
);

export const getSessionId: () => string = () => `${cookie.get('_session_id') || uuidv4()}`;

export const shouldFetchUserData = createSelector(
    [
        isUserAtLeastPartiallyRegistered,
        getUserData,
        getLoadTimeForUserData,
        isUserDataLoading,
        getUserRegistrationStatus,
    ],
    (userAtLeastPartiallyRegistered, userData, loadTime, isLoading, bidderType) => {
        const isTokenVerified =
            bidderType === UserRegistrationStatus.Registered || bidderType === UserRegistrationStatus.Partially;
        const isUserVerified = isTokenVerified || userAtLeastPartiallyRegistered;
        if (!isUserVerified) {
            return false;
        }

        if (userData) {
            const diff = Date.now() - loadTime;
            if (diff < USER_SLICE_CACHE_TIME) {
                return false;
            }
        }
        return !isLoading;
    }
);
